import { WasteWaterRow } from "../types/WasteWaterRow"

const API_URL = "/api/wastewater"
console.log('API_URL:', API_URL);

export const getAllWasteWater = async (): Promise<WasteWaterRow[]> => {
    console.log('Fetching all rows from:', `${API_URL}/get`);
    
    try {
        const response = await fetch(`${API_URL}/get`);
    if(!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data =await response.json();
    console.log('API response:', data);
    return data;
    } catch (error) {
        console.error('Error fetching notes:', error);
        return [];
    }
};

