// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f4f8;
    text-align: center;
  }
  
  .header {
    background-color: #2e8b57;
    color: white;
    padding: 20px;
  }
  
  .content {
    margin-top: 50px;
    padding: 20px;
  }
  
  .start-button {
    background-color: #4682b4;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #2e8b57;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/splashPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".splash-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n    background: #f0f4f8;\r\n    text-align: center;\r\n  }\r\n  \r\n  .header {\r\n    background-color: #2e8b57;\r\n    color: white;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .content {\r\n    margin-top: 50px;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .start-button {\r\n    background-color: #4682b4;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .footer {\r\n    position: absolute;\r\n    bottom: 0;\r\n    width: 100%;\r\n    text-align: center;\r\n    padding: 10px;\r\n    background-color: #2e8b57;\r\n    color: white;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
