import React, { useState, useEffect } from 'react';

const Personalization: React.FC = () => {
  
  const initialColorScheme = localStorage.getItem('colorScheme') || 'light';
  const initialBackgroundImage = localStorage.getItem('backgroundImage') || '';

  const [backgroundImage, setBackgroundImage] = useState<string | undefined>(initialBackgroundImage);
  const [colorScheme, setColorScheme] = useState<string>(initialColorScheme);

  // Function to handle background image upload
  const handleBackgroundImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        setBackgroundImage(imageDataUrl);
        localStorage.setItem('backgroundImage', imageDataUrl);  // Save to localStorage
        document.body.style.backgroundImage = `url(${imageDataUrl})`;
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle color scheme change
  const handleColorSchemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const scheme = e.target.value;
    setColorScheme(scheme);
    localStorage.setItem('colorScheme', scheme);  // Save to localStorage
    applyColorScheme(scheme);
  };

  
  const applyColorScheme = (scheme: string) => {
    if (scheme === 'dark') {
      document.body.style.backgroundColor = '#333';
      document.body.style.color = '#fff';
    } else {
      document.body.style.backgroundColor = '#fff';
      document.body.style.color = '#000';
    }
  };

  
  const handleRemoveBackgroundImage = () => {
    setBackgroundImage(undefined);
    localStorage.removeItem('backgroundImage');  // Remove from localStorage
    document.body.style.backgroundImage = '';  // Remove background image
  };

  
  useEffect(() => {
    applyColorScheme(colorScheme);
    if (backgroundImage) {
      document.body.style.backgroundImage = `url(${backgroundImage})`;
    }
  }, []);

  return (
    <div className="personalization">
      <h2>Personalize Your App</h2>

      {/* Color Scheme Selector */}
      <label htmlFor="colorScheme">Choose Color Scheme: </label>
      <select id="colorScheme" value={colorScheme} onChange={handleColorSchemeChange}>
        <option value="light">Light</option>
        <option value="dark">Dark</option>
      </select>

      {/* Background Image Uploader */}
      <label htmlFor="backgroundUpload">Upload Background Image: </label>
      <input
        type="file"
        id="backgroundUpload"
        accept="image/*"
        onChange={handleBackgroundImageChange}
      />

      {/* Remove Background Image Button */}
      {backgroundImage && (
        <button onClick={handleRemoveBackgroundImage}>
          Remove Background Image
        </button>
      )}
    </div>
  );
};

export default Personalization;