import React from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import Personalization from './components/personalization';

import Instructions from './components/Instructions';
import CurrentDateDisplay from './components/CurrentDateDisplay'; // Import CurrentDateDisplay

const App: React.FC = () => {
  return (
    <div className="App">
      <CurrentDateDisplay/>
      <h1>TODO Notes Application</h1>
      <Personalization />

      <Instructions />
      <AddNote />
      <NoteList />
    </div>
  );
};


// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <h1>TODO Notes Application</h1>
//     </div>
//   );
// };

export default App;