import React from 'react';
import "./components/splashPage";
import SplashPage from './components/splashPage';
import "./components/AllWasteWaterPage";
import AllWasteWaterPage from './components/AllWasteWaterPage';

const App: React.FC = () => {
  return (
    <div className="App" data-testid='App'>
      <h1>WaterWise App</h1>
      <AllWasteWaterPage />
      {/* <SplashPage/> */}
    </div>
  );
};


export default App;