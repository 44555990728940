import React, { useEffect, useState } from 'react';

// Display the current date on the webpage
const CurrentDateDisplay: React.FC = () => {
  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    const today = new Date();
    const dataAsString = today.toLocaleDateString(undefined, {
      weekday: 'long',
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    });
    setCurrentDate(dataAsString); // Set current date as a string on webpage
  }, []);

  //display date
  return (
<div style={{ textAlign: 'right', fontWeight: 'bold', marginBottom: '10px' }}>
      <p>{currentDate}</p>
    </div>
  );
};

export default CurrentDateDisplay;
