import React from 'react';

const Instructions: React.FC = () => {
  return (
    
      <div>
        <h2>Instructions</h2>
        <ol className="instructions__list">
            <li>Add your note in the textbox below</li>
            <li>Press "Add Note"</li>
            <li>See your note added to the list!</li>
        </ol>
      </div>
    
  );
};

export default Instructions;
